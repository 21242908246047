<template>
  <div class="panel">
    <div class="cont">
      <h1>Black City Panel</h1>
      <div v-if="loggedin">
        <div v-if="userdata">
          <p>Steam: {{userdata.steam}}</p>
          <p>Név: {{userdata.name}}</p>
          <p>Születési dátum: {{userdata.dob}}</p>
          <p>Magasság: {{userdata.height}} cm</p>
          <p>Prémiumpont: {{userdata.pp}} PP</p>
          <p>Telefonszám: {{userdata.phonenumber}}</p>
          <p>Szerveren töltött idő: {{userdata.playtime}} perc</p>      
        </div>
        <div v-else>
          <p>Sajnos nem találtunk az adatbázisunkban! Kérlek csatlakozz a szerverre, figyelj hogy ez a steamed legyen csatlakoztatva a FiveM-edhez!</p>
        </div>
        <div class="links">
          <router-link v-if="loggedin" to="/panel/pp"><i class="bi bi-basket"></i> PP Vásárlás</router-link>
          <a href="/api/auth/logout"><i class="bi bi-box-arrow-left"></i> Kijelentkezés</a> <br>
        </div>
      </div>
      <a v-else href="/api/auth/steam" style="margin-top: 10px;"><i class="bi bi-steam"></i> Bejelentkezés</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PanelView',
  data() {
    return {
      loggedin:false,
      userdata:false 
    }
  },
  async mounted() {
    const response = await fetch("/api/auth/data", {
      credentials: 'include'
    })
    if (!response || !response.ok) return 
    try {
      const data = await response.json()
      if (!data) return
      this.loggedin = data

      const userresponse = await fetch("/api/player/data", {
        credentials: 'include'
      })
      if (!userresponse || !userresponse.ok) return 
      try {
        const user = await userresponse.json()
        if (!user) return 
        console.log(user)
        if (user.found) {
          this.userdata = user 
        } else {
          this.userdata = false 
        }
      } catch {
        this.userdata = false 
      }
    } catch (error) {
      this.loggedin = false
    }
  }
}
</script>

<style>
.panel {
  min-height: 40vw;
  background-image: url("../assets/bg3.webp");
  background-size: cover;
  padding: 10px;
}

.panel .cont {
  background-color: #1F1F1F;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  border-radius: 5px;
}

.cont h1 {
  margin: 5px;
  margin-bottom: 20px;
}

.panel a {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    background: #364FC7;
    color: #FFF;
    padding: 8px 12px;
    text-decoration: none;
    margin: 20px;
}

@media  (max-width: 651px) {
  .panel {
    background: #1F1F1F;
  }
  .panel .cont {
    height: 100%;
    transform: none;
    position: relative;
    padding: 0;

    top: 0;
    left: 0;
  }
  .panel a {
    margin: 5px;
  }
}
</style>