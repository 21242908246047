<template>
    <div class="site-cont" v-html="sitehtml">
    </div>
</template>
  
<script>
  export default {
    name: 'ManagerView',
    data() {
        return {
          sitehtml : "<h2>Betöltés...</h2>"
        }
    },
    async mounted() {
      if (!this.$route.params.site) return;
      const sitename = this.$route.params.site
      window.addEventListener("message", (event) => {
        if (event.data.type !== "loadheight") return;
        var iframeel = document.getElementById('siteiframe')
        if (iframeel) {
          iframeel.style.height = event.data.height
        }
      });
      try {
        const response = await fetch("/api/sitedata/"+sitename)
        const sitecontent = await response.json()

        if (!sitecontent.valid) {
          this.sitehtml = "<h2>A keresett oldal nem található</h2>"
          return
        }

        this.sitehtml = `<iframe 
        id="siteiframe"
        src="/extrasites/${sitename}" 
        onload='javascript:(
          function(o) {
          console.log("betöltve")
            o.style.height=o.contentWindow.document.body.scrollHeight+"px";
            var elements = o.contentWindow.document.querySelectorAll( "a" );
            elements.forEach(function(element) {
              console.log(element.innerHTML);
              element.target = "_top";
            });
          }(this)

        );' 
        style="width:100%; height:500px; border:none; overflow:hidden;" frameborder="no"
        </iframe>
        `
      } catch {
        this.sitehtml = "<h2>A keresett oldal nem található</h2>" 
      }
    },
  }
</script>
  
<style>
</style>