import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PanelView from '../views/PanelView.vue'
import PPView from '../views/pp/PPView.vue'
import PPCancel from '../views/pp/PPCancel.vue'
import PPSuccess from '../views/pp/PPSuccess.vue'
import Infos from '../views/infoboard/Infos.vue'
import Manager from '../views/infoboard/Manager.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/panel',
    name: 'panel',
    component: PanelView
  },
  {
    path: '/panel/pp',
    name: 'pp',
    component: PPView
  },
  {
    path: '/panel/pp/cancel',
    name: 'ppcancel',
    component: PPCancel
  },
  {
    path: '/panel/pp/success',
    name: 'ppsuccess',
    component: PPSuccess
  },
  {
    path: '/infoboard',
    name: 'infoboard',
    component: Infos
  },  
  {
    path: '/infoboard/:site',
    name: 'infoboardsite',
    component: Manager
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
