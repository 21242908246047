<template>
    <div class="infoboard">
      <div class="info-header">
        <h1>Információk</h1>
        <div class="triangle"></div>
      </div>
      <div class="allsites">
        <div class="infosite site-kezdoknek" @click="this.$router.push('/infoboard/kezdoknek')">
          <p>Kezdőknek <br><i class="bi bi-arrow-right"></i></p>
        </div>
        <div class="infosite site-pp" @click="this.$router.push('/infoboard/events')">
          <p>Eventek <br><i class="bi bi-arrow-right"></i></p>
        </div>
        <div class="infosite site-factions" @click="this.$router.push('/infoboard/factions')">
          <p>Frakció pályázás <br><i class="bi bi-arrow-right"></i></p>
        </div>
        <div class="infosite site-rule" @click="this.$router.push('/infoboard/rules')">
          <p>Szerver szabályzat <br><i class="bi bi-arrow-right"></i></p>
        </div>
        <div class="infosite site-dcrule" @click="this.$router.push('/infoboard/dcrules')">
          <p>Discord unban + szabályzat <br><i class="bi bi-arrow-right"></i></p>
        </div>
        <div class="infosite site-jobs" @click="this.$router.push('/infoboard/jobs')">
          <p>Munkák <br><i class="bi bi-arrow-right"></i></p>
        </div>
        <div class="infosite site-robberies" @click="this.$router.push('/infoboard/robberies')">
          <p>Rablások <br><i class="bi bi-arrow-right"></i></p>
        </div>
      </div>
    </div>
</template>
  
<script>
  export default {
    name: 'InfosView'
  }
</script>
  
<style>
.infoboard .info-header {
  position: relative;
  top: 0;
  height: 15vw;
  background-image: url('/src/assets/header.webp');
  background-size: cover;
}
.infoboard .info-header h1 {
  position: absolute;
  color: white;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.info-header .triangle {
  z-index: 10;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-bottom: 4vw solid #1F1F1F;
  border-left: 100vw solid transparent;
}


.site-kezdoknek {
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.803)), url('/src/assets/kezdoknek.webp');
}
.site-factions {
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.803)), url('/src/assets/factions.webp');
}
.site-rule {
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.803)), url('/src/assets/rules.webp');
}
.site-dcrule {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.488), rgba(0, 0, 0, 0.803)), url('/src/assets/discord-rules.webp');
}
.site-pp {
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.803)), url('/src/assets/premium.webp');
}
.site-jobs {
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.803)), url('/src/assets/jobs.webp');
}
.site-robberies {
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.803)), url('/src/assets/robberies.webp');
}

.infosite {
  position: relative;
  color: white;
  height: 15vw;
  width: 15vw;
  border-radius: 1vw;
  background-size: cover;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.6s;
}
.infosite:hover {
  transform: scale(1.1);
}
.infosite p {
  font-size: 1.3vw;
  font-weight: bolder;
  text-shadow: 2px 2px black;
}

.allsites {
  padding: 4vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1vw;
  flex-wrap: wrap;
}

@media (min-width: 860px) and (max-width: 1150px) {
  .infoboard .info-header {
    height: 20vw;
  }
  .infosite {
    width: 20vw;
    height: 20vw;
  }
}

@media (min-width: 650px) and (max-width: 861px) {
  .infoboard .info-header {
    height: 25vw;
  }
  .infosite {
    width: 25vw;
    height: 25vw;
  }
  .infosite p {
    font-size: 1.8vw;
  }
}

@media  (max-width: 651px) {
  .infoboard .info-header {
    height: 35vw;
  }
  .infosite {
    width: 37vw;
    height: 37vw;
  }
  .infosite p {
    font-size: 3.2vw;
  }
}
</style>