<template>
    <div class="ppview">
      
      <form action='/api/payments/create' method="post">
        <h1>Prémium Pont vásárlás</h1>
        <p>Add meg milyen értékben vásárolnál Prémiumpontot</p>
        <p>Minimum összeg: 500Ft Maximum összeg: 40.000Ft</p>
        <input type="number" name="price" class="numinp" id="ppinput" v-model="ppinput" @change="checkinput">
        <p>{{ppbuy}}</p>
        <input type="email" name="email" class="txtinp onelineinp" placeholder="Email cím" id="emailinput" v-model="email" @input="checkemail"> 
        <p v-if="!emailvalid" style="color:#FA5252;" :class="emailclasslist">Kérlek adj meg valós email címet</p>
        <p>A vásárlás után a megadott e-mail címre autómatiuksan nyugtát küldünk, amennyiben ez nem megfelelő, keressen fel minket Discordon</p>
        <!--<p>A vásárlással autómatikusan elfogadja az támogatásra vonatkozó szabályzatot, illetve kijelneti hogy a fentebb megadott adatok valósak</p>-->
        <p>A vásárlással autómatikusan elfogadja az <a href="/infoboard/aszf" target="_blank">Általános Szerződési feltételeket</a> és az <a href="/infoboard/adatvedelem" target="_blank">Adatvédelmi nyilatkozatot</a>, illetve kijelneti hogy a fentebb megadott adatok valósak</p>
        <button type="submit" name="paymentmehtod" value="paypal" :disabled="!emailvalid" @mouseenter="warndis"><i class="bi bi-paypal"></i> Fizetés (PayPal)</button>
        <button type="submit" name="paymentmehtod" value="stripe" :disabled="!emailvalid" @mouseenter="warndis"><i class="bi bi-credit-card"></i> Fizetés (Bankkártya)</button>
        <!--<button type="submit" name="paymentmehtod" value="simplepay" :disabled="!emailvalid"><i class="bi bi-credit-card"></i> Fizetés (Bankkártya)</button>-->
      </form>
    </div>
</template>
  
<script>
  export default {
    name: 'PanelView',
    data() {
        return {
            sale:false,
            ppinput:1000,
            emailvalid: false,
            email:undefined,

            emailclasslist: [] 
        }
    },
    computed: {
        ppbuy() {
            if (!this.sale) return this.ppinput + "Ft-ért - " + this.ppinput + " PP"
            if (this.sale.type == "boost") {
                return this.ppinput + "Ft-ért - " + this.ppinput*this.sale.value+ " PP, a " + this.ppinput + " PP helyett"
            }
            return this.ppinput + "Ft-ért - " + this.ppinput + " PP"
        }
    },
    methods: {
        warndis(e) {
            if (!this.emailvalid) {
                console.log("shake")
                e.preventDefault()
                this.emailclasslist = ['shake']
                setTimeout(() => {
                    this.emailclasslist = []
                }, 820)
            }
        },
        checkinput() {
            if (this.ppinput < 500) {
                this.ppinput = 500;
            }
            if (this.ppinput > 40000) {
                this.ppinput = 40000;
            }
        },
        checkemail() {
            const re = /\S+@\S+\.\S+/;
            if (this.email && re.test(this.email)) {
                this.emailvalid = true
            } else {
                this.emailvalid = false
            }
        }
    },
    async mounted() {
        const response = await fetch("/api/payments/sale", {
            credentials: 'include'
        })
        if (!response || !response.ok) return 
        try {
            const data = await response.json()
            if (!data) return
            this.sale = data
        } catch (error) {
            this.sale = false
        }
    }
  }
</script>

<style>
.ppview {
    min-height: 30vw;
}
.ppview a {
    color: white;
}
.ppview form {    
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
}
.ppview button {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    background: #364FC7;
    color: #FFF;
    padding: 8px 20px;
    text-decoration: none;
    margin: 20px;
    font-family: "Afacad Flux", sans-serif;
    cursor: pointer;
}

.txtinp {
    background: #364FC7;
    color: #FFF;
    padding: 8px 20px;
    text-decoration: none;
    margin: 20px;
    font-family: "Afacad Flux", sans-serif;
    border: none;
    border-radius: 4px;
}

.numinp {
    background: #364FC7;
    color: #FFF;
    padding: 8px 20px;
    text-decoration: none;
    margin: 20px;
    font-family: "Afacad Flux", sans-serif;
    border: none;
    border-radius: 4px;
}

.numinp::-webkit-outer-spin-button,
.numinp::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.numinp[type=number] {
  -moz-appearance: textfield;
}

.onelineinp {
    width: 35vw;
}
div.onelineinp {
    width: calc(35vw + 40px);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}
.halflineinp {
    width: 40%;
    margin: 0;
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@media  (max-width: 651px) {
    .ppview {
        height: auto;
    }
    .onelineinp {
    width: 80vw;
}
div.onelineinp {
    width: calc(80vw + 40px);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}
}
</style>