<template>
    <div class="ppcancel">
      <h1>Prémium Pont vásárlás</h1>
      <p>Sikertelen vásárls</p>
      <p v-if="message">{{ message }}</p>
      <router-link to="/panel"><i class="bi bi-house"></i> Vissza a panelba</router-link>
    </div>
</template>

<script>
export default {
  name: 'PPCancel',
  data() {
    return {
      message:false
    }
  },
  mounted() {
    if (this.$route.query.message) {
      this.message = this.$route.query.message
    }
  }
}
</script>

<style>
.ppcancel a {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
    background: #364FC7;
    color: #FFF;
    padding: 8px 12px;
    text-decoration: none;
    margin: 20px;
}

@media  (max-width: 651px) {
    .ppcancel {
        height: auto;
        padding: 10px;
    }
}
</style>
